window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    // Bootstrap ToolTips
    [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    });
} catch (e) {
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

/**
 * Register the CSRF Token as a common header with RESTfulizerJS so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;

    $('[data-method="POST"], [data-method="PUT"], [data-method="PATCH"], [data-method="DELETE"]')
        .restfulizer({ parse: true }) // Parse the URL parameters (allow to send them to POST)
        .find('form')
        .append("<input type='hidden' name='_token' value='" + token.content + "'>")
        ;

    $('[data-method="PATCH"]').removeAttr('onclick').on('click', function appendSelectedIds(event)
    {
        event.preventDefault();

        var form = this.getElementsByTagName('form')[0];
        document.body.querySelectorAll('input[name="ids[]"]:checked').forEach(function myFunction(item, index)
        {
            var input = document.createElement('input');
            input.name = 'ids[]';
            input.type = 'hidden';
            input.value = item.value;
            form.appendChild(input);
        });
        form.submit();
    });

    $('[data-method="DELETE"][data-confirm="true"]').removeAttr('onclick').on('click', function confirmDelete(item, index)
    {
        event.preventDefault();
        Swal.fire({
            confirmButtonText: 'DELETE',
            icon: 'question',
            showCloseButton: true,
            showCancelButton: true,
            text: 'This record will be permanantly deleted. This action is irreversable.',
            title: 'Are you sure?',
        }).then(function (result) {
            if (result.isConfirmed) {
                item.currentTarget.getElementsByTagName('form')[0].submit();
            }
        });
    })
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
